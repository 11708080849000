.container {
  composes: gridSmallWrapper from '@/global/style/Layout.module.css';
  margin-top: var(--spacing-7);
}

.imageContainer {
  position: relative;
  height: 45vh;
  min-height: 600px;
  max-height: 800px;
}

@media (min-width: 640px) {
  .container {
    margin-top: var(--spacing-9);
  }
}

